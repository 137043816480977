import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const SoloGuidesWOBLUpper: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>WOBL: Upper Floors</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_wobl_upper.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>WOBL: Upper Floors</h1>
          <h2>
            Workshop of Brilliant Light Demon Castle Upper Floors in Solo
            Leveling: Arise decoded.
          </h2>
          <p>
            Last updated: <strong>27/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Workshop of Brilliant Light Demon Castle Upper Floors" />
        <p>
          The newest floors of the Demons’ castle have been released, and they
          bring with them a new type of gear: Cores. Those will greatly boost
          Jinwoo and your Hunters but will be jealously guarded by 4 Bosses,
          each bringing their own mechanics to spice up the fights and keep you
          on your toes.
        </p>
        <p>
          We’ll try to keep it short and concise for now as Hard mode isn’t
          available yet and could bring more mechanics and gimmicks.
        </p>
        <p>
          For more information on the Cores, check out the little guide we
          cooked up for the occasion at
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Core Review"
            link="/solo-leveling/guides/core-review"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_core.webp"
                alt="Core"
              />
            }
          />
        </Row>
        <SectionHeader title="Soul Shepherd Methus" />
        <p>
          Not too much to say about this iteration of Methus, as an entry Boss
          he’ll mostly be a DPS check to make sure your gear is good enough to
          proceed further. The skeletons he summons can be largely ignored and
          will die by the AoEs of your carry, and his special gimmick is
          extremely easy to clear: you just have to step onto the symbols within
          the reduced area a total of 5 times to cancel his nuke. As a small
          piece of advice, the symbols will never appear on the same side one
          after the other, so if you’re unsure where it will appear next, just
          run in the opposite direction of the symbol you stepped onto. Keep in
          mind the boss attacks may push you out of the circle where the
          mechanic happens, so make sure to dodge properly or you might end up
          killed - this may be more relevant once the Hard mode version is
          released.
        </p>
        <SectionHeader title="Esil Radiru" />
        <p>
          As the second Boss of the dungeon, Esil shouldn’t prove to be too much
          of a challenge when it comes to her mechanics, so here are a couple of
          tricks to optimize your runs and avoid running around the map too
          much:
        </p>
        <ul>
          <li>
            Whenever she dashes and leaves a trail of fire, keep your shadow
            step for when she comes back, otherwise you’ll have to walk back to
            her;
          </li>
          <li>
            When she summons her two crystals on opposite sides of the map, run
            towards the closest one and make sure to keep your shadow step to
            teleport directly at the second one’s location, saving you quite a
            bit of time;
          </li>
          <li>
            After the cutscene, quickly expanding pools of fire will appear all
            over the map, and interestingly enough the safest spot will be right
            in the center of the arena, underneath Esil.Otherwise, you can move
            to the edge of the map and find the place that will not be covered
            by the pool of fire.
          </li>
          <li>
            Be careful however, because just after the pools explode Esil will
            come crashing down on the middle of the map, and a fairly powerful
            shockwave will spread from the point of impact, all the way to the
            edges of the battlefield. Make sure to dodge a second or two after
            the pools explode if you’re sitting under Esil, otherwise you’ll
            take heavy damage on top of being knocked up in the air.
          </li>
        </ul>
        <SectionHeader
          title="Baran of White Flames
"
        />

        <p>
          Nameless King anyone? With that said, Mounted Baran will be the first
          challenging enemy you’ll face if your power is lacking. High health,
          Mana drain, some attacks debuff you even as you dodge them, etc… The
          mechanics of the fight themselves will be pretty simple, such as
          running around to catch the Lightning orbs during Kaisel’s charge up
          phase, or staying away from the lightning puddles on the ground. With
          that said, here are a couple of tips to make your run smoother:
        </p>
        <ul>
          <li>
            As a rule of thumb, remember that every ranged Lightning Attack from
            Kaisel will apply a Mana Drain + HP dot on you, so even if the
            attacks don’t deal too much damage they could make it much harder to
            play mana hungry Hunters if you just stand still and take lightning
            showers;
          </li>
          <li>
            Starting phase 2, Kaisel will start casting Thunderstrikes on the
            map, around and away from himself and those will also target both
            the Hunters and the Shadows. It makes it difficult to properly
            access him, but usually at least one side of Kaisel will be mostly
            clear of any Lightning puddle so look for that spot and stick to it
            if the Mana drain bothers you too much;
          </li>
          <li>
            Also during phase 2, Kaisel will take off and start a series of 5
            Bombardments, littering the area with more Lightning puddles before
            shooting a powerful Orb of Lightning as he lands. Make sure to keep
            a dodge for that one, as it will sting quite a bit otherwise. A nice
            trick here is to try to stick to the edges of the map for the first
            4 Bombardments and then rush back to the center of the arena for the
            final one, giving you much more space to maneuver.
          </li>
        </ul>
        <SectionHeader
          title="Baran, the Demon
"
        />
        <p>
          Nameless King entrance, again? This fight will be your final
          challenge, and oh boy is the encounter getting full points for Style.
          Baran won’t be too strong, in fact he’s very well balanced on the side
          of mechanics when you compare him to good ol’ Vulcan, especially on
          release. The fight will be mostly about keeping track of your
          available dodges, as well as being patient whenever Baran starts his
          long combos.
        </p>
        <p>
          Beware that the points we’ll cover will spoil the flow of the fight if
          you’ve never seen it before.
        </p>
        <p>The Baran fight is divided into 2 phases, each distinct:</p>
        <ul>
          <li>
            At the beginning of the fight, Baran faces you with his Longsword (
            his very, very long and huge Longsword), his attacks will be
            relatively simple and slow, but will reach a fairly wide area. He’ll
            teleport, disappear, fire a beam and call down lightning strikes,
            etc, etc..
          </li>
          <li>
            Midway through that phase, Baran will become untargetable and
            teleport before the rundown altar and will start sending massive
            Walls of electricity at SJW. Keep moving using your dodge’s speed
            buff, and make sure to progressively move towards the extremities of
            the map to avoid the final Wall. After that, he’ll turn gigantic
            once, try to stay where he was (next to the Altar) to be protected
            by Esil’s Shield for the last strike.
          </li>
          <li>
            Once you take him down, he’ll enter Phase 2 and pick up his Daggers.
            Expect him to be faster while keeping all his previous abilities,
            Walls and Giganto aside. He’ll gain a new ability, the Kang Taeshik
            special where he starts moving so fast he leaves afterimages. After
            two series of fast moves from him, dodge. Repeat that 3 times and
            you’ll be done with that mechanic.
          </li>
          <li>
            Halfway through, you’ll start a badass QTE cutscene of SJW and Baran
            exchanging blows at high speed, and you’ll have to time your
            taps/clicks to be good or better. Succeeding enough times will put
            Baran into a Break state, during which he’ll take significant
            damage.
          </li>
          <li>
            Lastly, as Baran finally fears for his life, he’ll start preparing
            one final, sure kill move. Keep the fight close to the center of the
            map so you won’t have any distance to run and put an end to his
            tyranny
          </li>
          <li>
            It’s also worth mentioning that dealing enough damage before Baran
            starts his last resort attack can entirely bypass this phase and
            kill him - this is valid in Normal mode, but might be more difficult
            in Hard mode, as he might also get an HP stopper.
          </li>
        </ul>
        <SectionHeader title="Team Building" />
        <p>
          This time around the bosses are weak to both Wind and Dark, two
          already strong elements both for SJW and the Hunters. While the two
          teams offer similar dps, it's very important to note how currently a
          mono dark team isn't as viable as wind due for the hunter boss stages
          to the lack of healing and shielding. It's possible that, when Isla
          drops, the hard mode for WOBL will also unlock, so we'll get a chance
          to test the dark team again soon.
        </p>
        <p>
          For Jinwoo, every SSR weapon from these elements will be a viable
          option while Westwind still remains strong but will require more
          precise gameplay as the Bosses move around a LOT. The Plum Sword still
          offers excellent buffs and reasonable damage at A3, so it remains an
          excellent pick as a secondary weapon. If you’re having a hard time
          managing your Dodges between offense and defense, slotting in Woo
          Jinchul as Support will provide you two extra dodge charges as well as
          good break. In the same fashion, the Swiftness Blessing Stone will
          offer extra Dodge, and the Daily Quest Completion one will give you
          more room for errors and can help you cheese certain mechanics that
          would nuke you.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesWOBLUpper;

export const Head: React.FC = () => (
  <Seo
    title="WOBL: Upper Floors Review | Solo Leveling: Arise | Prydwen Institute"
    description="Workshop of Brilliant Light Demon Castle Upper Floors in Solo Leveling: Arise decoded."
    game="solo"
  />
);
